import { TRUTHY_REGEXP } from '../constants'
import { ALLOWED_PRODUCTION_TENANTS, ALLOWED_TEST_TENANTS } from '@whispli/types/constants'

export enum NODE_ENV {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  TEST = 'test',
}

export const isDevelopment = ((): boolean =>
  (Object.keys(import.meta.env ?? {}).length
    ? import.meta.env.MODE
    : typeof process !== 'undefined'
      ? process.env.NODE_ENV
      : '') === NODE_ENV.DEVELOPMENT)()

export const isProduction = ((): boolean =>
  (Object.keys(import.meta.env ?? {}).length
    ? import.meta.env.MODE
    : typeof process !== 'undefined'
      ? process.env.NODE_ENV
      : '') === NODE_ENV.PRODUCTION)()

export const isTest = ((): boolean =>
  (Object.keys(import.meta.env ?? {}).length
    ? import.meta.env.MODE
    : typeof process !== 'undefined'
      ? process.env.NODE_ENV
      : '') === NODE_ENV.TEST)()

export const isDebug = ((): boolean =>
  TRUTHY_REGEXP.test(('env' in import.meta
    ? import.meta.env.VITE_DEBUG
    : typeof process !== 'undefined'
      ? process.env.VITE_DEBUG
      : '')  || ''))()

export const isTestTenant = ((hostname: string = window.location.hostname) => [
  ...ALLOWED_PRODUCTION_TENANTS,
  ...ALLOWED_TEST_TENANTS,
].includes(hostname))()

if (import.meta.hot) {
  import.meta.hot.accept()
}
