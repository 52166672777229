/* eslint-disable no-debugger, no-console */

import { isDebug, isDevelopment } from '../env'

/**
 * @param {Error} error
 * @param {Boolean} log
 *   If true and in development, logs the error
 * @param {Boolean} setBreakpoint
 *   If true, sets a breakpoint
 * @param {Function} onContinue
 *   If given, function will be called with `error`
*/
export const __debug__ = (
  error: Error | any,
  log: boolean = true,
  setBreakpoint: boolean = true,
  onContinue?: ((error: Error | any) => void) | undefined,
): void => {
  if (log && isDevelopment) {
    console.error(error)
  }

  if (setBreakpoint && isDebug && isDevelopment) {
    debugger
  }

  if (typeof onContinue === 'function') {
    onContinue(error)
  }
}
