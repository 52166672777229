import zipObj from 'ramda/es/zipObj'
import invert from 'ramda/es/invert'
import equals from 'ramda/es/equals'

/**
 * A `PUBLIC_PATH_ENUM` member is provided via env (VITE_PUBLIC_PATH) and injected into HTML output.
 * The provided `PUBLIC_PATH_ENUM` is asserted against APP_ENUM.
 * If the PUBLIC_PATH_ENUM is not found in APP_ENUM, the PUBLIC_PATH_ENUM is considered invalid and the build will fail.
 * This build time integrity allows us to use PUBLIC_PATH_ENUM to determine the app name at runtime.
 * But, use sparingly, this is an escape hatch; we'd rather tell not ask.
 *
 * @example
 *   document.baseURI.replace(window.origin, '') === PUBLIC_PATH_ENUM.INBOX_WEB ? 'do this' : 'or that'
 *
 * @see https://gitlab.com/whispli/tenant-api-v2/whispli/blob/4f4f44cd7606dd35fc9c11ee54c1a41fc47c741c/packages/tools/src/vite/index.ts#L76
 * @see https://gitlab.com/whispli/devops/flux/blob/5c4d466834b01b6ad74460a498cb53d096ef2886/env/aws-au-staging/workload/ingresses/ingress-frontend.yaml#L52
 *
 * @note Must be sorted alphabetically
 */
export enum PUBLIC_PATH_ENUM {
  /** Whispli Core (v2) web-app */
  CORE_WEB = '/v2/',
  /** Whispli Core (legacy) web-app */
  CORE_WEB_LEGACY = '/v1/',
  /** Whispli Inbox web-app */
  INBOX_WEB = '/inbox/',
  /** Whispli Landing Page web-app */
  PAGES_WEB = '/lp/',
  PAGES_WEB_CUSTOM = '/pages/',
  /** Whispli Portal web-app */
  PORTAL_WEB = '/',
  /** Whispli Pulse web-app */
  PULSE_WEB = '/pulse/',
}

/** Must be sorted alphabetically */
export enum APP_ENUM {
  CORE_WEB = 'core-web-v2',
  CORE_WEB_LEGACY = 'core-web',
  INBOX_WEB = 'inbox-web',
  PAGES_WEB = 'pages-web',
  PAGES_WEB_CUSTOM = 'pages-web',
  PORTAL_WEB = 'portal-web',
  PULSE_WEB = 'pulse-web',
}

if (!equals(Object.keys(APP_ENUM), Object.keys(PUBLIC_PATH_ENUM))) {
  // Runtime sort integrity check
  throw new Error('APP_ENUM and PUBLIC_PATH_ENUM must have the same number and order of keys')
}

export const PUBLIC_PATH_TO_APP_MAP: Record<PUBLIC_PATH_ENUM, APP_ENUM> = zipObj(
  Object.values(PUBLIC_PATH_ENUM),
  Object.values(APP_ENUM)
)

/** Supports Multi-Page-App ie. pages-web */
export const APP_TO_PUBLIC_PATH_MAP: Record<APP_ENUM, PUBLIC_PATH_ENUM> = {
  ...invert(PUBLIC_PATH_TO_APP_MAP),
  [APP_ENUM.PAGES_WEB]: [
    PUBLIC_PATH_ENUM.PAGES_WEB,
    PUBLIC_PATH_ENUM.PAGES_WEB_CUSTOM,
  ],
}

export enum LOCALHOST_PORT_ENUM {
  /** https://do-not-delete.whispli.company:3001/v2/ */
  CORE_WEB = 3001,
  /** https://do-not-delete.whispli.company:3000/v1/ */
  CORE_WEB_LEGACY = 3000,
  /** https://do-not-delete.whispli.company:3001/inbox/ */
  INBOX_WEB = 8080,
  /** https://do-not-delete.whispli.company:8081/lp/ */
  PAGES_WEB = 8081,
  /** https://do-not-delete.whispli.company:8081/pages/ */
  PAGES_WEB_CUSTOM = 8081,
  /** https://portal.whispli.company:8082/ */
  PORTAL_WEB = 8082,
  /** https://do-not-delete.whispli.company:3001/pulse/ */
  PULSE_WEB = 8083,
}

/** Tenant API maintenance mode redirect URL */
export const MAINTENANCE_REDIRECT_URL = 'https://static.whispli.com'

/** Whispli Terms of Use */
export const CASE_MANAGER_TERMS_OF_USE_URL = 'https://www.whispli.com/terms-and-conditions/'

export const ALLOWED_TEST_TENANTS = Object.freeze([
  'default-aws-au-staging.whispli-staging.com',
  'default-aws-au-uat.whispli-uat.com',
  'default-aws-se-devops.whispli-staging.com',
  'default-az-au-dev.whispli-staging.com',
  'default-sw-fr-dev.whispli-staging.com',
  'default-yc-ru-dev.whispli-staging.com',
  'do-not-delete-aws-au-dev.whispli-staging.com',
  'e2e-test.whispli-staging.com',
  'e2e-test.whispli-uat.com',
  'localhost',
  'do-not-delete.whispli.company',
])

export const ALLOWED_PRODUCTION_TENANTS = Object.freeze([
  'default-aws-au-prod-cba.whispli.com',
  'default-aws-au-prod-scentregroup.whispli.com',
  'default-aws-au-prod-westpac.whispli.com',
  'default-aws-au-prod.whispli.com',
  'default-aws-ca-prod.whispli.com',
  'default-aws-cn-prod-veolia.whispli.com.cn',
  'default-aws-cn-prod.whispli.com.cn',
  'default-aws-de-prod-optiver.whispli.com',
  'default-aws-de-prod.whispli.com',
  'default-aws-fr-prod-bpce.whispli.com',
  'default-aws-fr-prod-generali.whispli.com',
  'default-aws-fr-prod-novobanco.whispli.com',
  'default-aws-fr-prod-veolia.whispli.com',
  'default-aws-fr-prod.whispli.com',
  'default-aws-kr-prod-petronas.whispli.com',
  'default-aws-kr-prod.whispli.com',
  'default-aws-sg-prod.whispli.com',
  'default-aws-us-prod-janestreet.whispli.com',
  'default-aws-us-prod.whispli.com',
  'default-az-fr-prod-asml.whispli.com',
  'default-az-fr-prod.whispli.com',
  'default-az-uae-prod.whispli.com',
  'default-az-uk-prod-mps.whispli.com',
  'default-az-uk-prod.whispli.com',
  'default-sw-fr-prod-integritashatosag.whispli.com',
  'default-sw-fr-prod.whispli.com',
  'default-yc-ru-prod.whispli.com',
  'staff-daniel.whispli.com',
])
